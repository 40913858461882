import { createRouter, createWebHistory } from '@ionic/vue-router';

import CardPlayer from '@/pages/CardPlayer.vue';
import CourseLessonPage from '@/pages/CourseLessonPage.vue';
import CoursesPage from '@/pages/CoursesPage.vue';
import HelpPageVue from '@/pages/HelpPage.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/courses'
  },
  {
    path: '/courses',
    component: CoursesPage,
  },
  {
    path: '/courses/:courseId',
    props: true,
    component: CourseLessonPage,
  },
  {
    path: '/courses/:courseId/player',
    props: true,
    component: CardPlayer,
  },
  {
    path: '/help',
    props: true,
    component: HelpPageVue,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
