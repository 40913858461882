
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonToast } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { folderOpenSharp, folderOpen, home, helpBuoy } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { Course } from './models';
import { useCoursesStore } from './stores/courses';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonToast,
  },
  setup() {
    const coursesStore = useCoursesStore()
    const dynamicPages = coursesStore.courses.map((course: Course) => ({
      title: course.title,
      url: `/courses/${course.id}`,
      iosIcon: folderOpen,
      mdIcon: folderOpenSharp
    }))

    const appPages = [
      {
        title: 'Home',
        url: '/courses',
        iosIcon: home,
        mdIcon: home
      },
      ...dynamicPages,
      {
        title: 'Help',
        url: '/help',
        iosIcon: helpBuoy,
        mdIcon: helpBuoy
      },
    ];

    let updateExists = ref(false)
    let registration: ServiceWorkerRegistration | undefined;
    navigator.serviceWorker.getRegistration().then(reg => {
      if (reg) {
        registration = reg;
        reg.addEventListener('updatefound', () => {
          console.debug('update found');
          updateExists.value = true;
        });
        // Check if there is a new service worker waiting to be activated
        // we never make calls to the server once loaded so no trigger
        // to check for updates
        setTimeout(() => reg.update(), 10000); // quick check on startup
        setInterval(() => { console.debug('checking'); reg.update() }, 1 * 60 * 1000);
      }
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // Here the actual reload of the page occurs
      console.debug('controllerchange')
      setTimeout(() => {
        console.debug('window.location', window.location)
        window.location.reload()
      }, 1000)
    })

    // Called when the user accepts the update
    const refreshApp = () => {
      console.debug('refreshApp', registration)
      updateExists.value = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration) return
      if (registration.waiting) {
        // send message to SW to skip the waiting and activate the new SW
        console.debug('sending skip waiting')
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      } else if (registration.active) {
        // if there is no sw waiting, then we can reload the page
        // because we got the updatefound event due to polling
        console.debug('no waiting, reloading')
        window.location.reload()
      }
    }

    const route = useRoute();

    return {
      appPages,
      buttons: [
        {
          side: 'end',
          text: 'Reload',
          handler: refreshApp
        }
      ],
      isSelected: (url: string) => {
        let path = route.path
        if (path.endsWith('/player')) {
          path = path.split('/player')[0]
        }
        return url === path ? 'selected' : ''
      },
      updateExists,
    }
  }
});
