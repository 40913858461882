import { Card, CardStatus, Proficiency } from '@/models'
import { defineStore } from 'pinia'
import { useCoursesStore } from './courses'
// import oneJump from "@/data/one-jump.json";
// import puppyEssentials from "@/data/dana-pike-puppy-essentials.json";
// import { carSharp } from 'ionicons/icons';

// const cardMerge = (existing: Card, newCard: Card) => {
//   return {
//     ...existing,
//     ...newCard,
//     answer: existing.answer,
//     // these will be moved into card-status store and removed from card store
//     isSelected: existing.isSelected,
//     isReview: existing.isReview,
//   }
// }

// const lessonMerge = (existing: Lesson, newLesson: Lesson) => {
//   // update existing lesson with all properties from new lesson. Add new cards to existing lesson and merge existing cards with new cards.
//   const cards = newLesson.cards.map(newCard => {
//     const existingCard = existing.cards.find(existingCard => existingCard.id === newCard.id)
//     if (existingCard) {
//       return cardMerge(existingCard, newCard)
//     } else {
//       return newCard
//     }
//   })
//   return {
//     ...existing,
//     ...newLesson,
//     cards
//   }
// }

// const courseMerge = (existing: Course, newCourse: Course) => {
//   // update existing course with all properties from new course. Add new lessons to existing course and merge existing lessons with new lessons.
//   const lessons = newCourse.lessons.map(newLesson => {
//     const existingLesson = existing.lessons.find(existingLesson => existingLesson.id === newLesson.id)
//     if (existingLesson) {
//       return lessonMerge(existingLesson, newLesson)
//     } else {
//       return newLesson
//     }
//   })
//   return {
//     ...existing,
//     ...newCourse,
//     lessons
//   }
// }

// const defaultCourses: Course[] = []
// if (process.env.VUE_APP_COURSES === 'one-jump') {
//   defaultCourses.push(oneJump)
// } else if (process.env.VUE_APP_COURSES === 'puppy-essentials') {
//   defaultCourses.push(puppyEssentials)
// }
export interface CardAndStatus {
  id: string
  courseId: string
  name: string
  lastReview: number | null
  proficiency: string
}

export const timestampToDate = (seconds: null | number): string => {
  if (seconds) {
    const date = new Date(seconds * 1000)
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
    return date.toLocaleDateString([], options)
  }
  return ''
}

const serverUpdate = async (data: CardStatus): Promise<CardStatus> => {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve(data)
    }, 100)
  })
}

export const useCardStatusStore = defineStore('card-status', {
  state: () => ({
    cardStatus: [] as CardStatus[],
  }),
  getters: {
    reviewCards(): CardStatus[] {
      return this.cardStatus.filter((card: CardStatus) => card.isReview)
    },
    groupedCardAndStatus(): any {
      const cards = useCoursesStore().getCards
      const merged = this.reviewCards.map((cardStatus: CardStatus) => {
        const card = cards.find((card: Card) => card.id === cardStatus.cardId)
        return {
          id: cardStatus.id,
          cardId: cardStatus.cardId,
          courseId: cardStatus.courseId,
          name: card?.name || '',
          lastReview: cardStatus.lastReview,
          proficiency: cardStatus.proficiency || Proficiency.LEARNING,
        }
      })
      // group cards by proficiency
      const grouped = merged.reduce((acc: any, card: CardAndStatus) => {
        const key = card.proficiency
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(card)
        return acc
      }, {})
      // sort cards by lastReview ascending
      Object.keys(grouped).forEach((key) => {
        grouped[key].sort((a: CardAndStatus, b: CardAndStatus) => {
          if (a.lastReview === null) {
            return 1
          }
          if (b.lastReview === null) {
            return -1
          }
          return a.lastReview - b.lastReview
        })
      })
      return grouped
    },
  },
  actions: {
    get(courseId: string, cardId: number): CardStatus | null {
      const index = this.cardStatus.findIndex(
        (cs) => cs.cardId === cardId && cs.courseId === courseId
      )
      if (courseId === undefined || index === -1) {
        return null
      } else {
        return this.cardStatus[index]
      }
    },
    insert(cardStatus: CardStatus) {
      this.cardStatus.push(cardStatus)
    },
    async update(cardStatus: CardStatus) {
      const newStatus = await serverUpdate({ ...cardStatus }) // simulate async response from server
      const index = this.cardStatus.findIndex(
        (cs) => cs.id === cardStatus.id && cs.courseId === cardStatus.courseId
      )
      if (index !== -1) {
        this.cardStatus[index] = newStatus
      }
    },
  },
  persist: {
    key: 'anc-card-status',
    // // create missing card-status entries for new cards
    // afterRestore: context => {
    //   const existing: CardStatus[] = context.store.cardStatus
    //   const allCards = defaultCourses.reduce((cards:Card[], course) => {
    //     return [...cards, ...course.lessons.reduce((lessonCards: Card[], lesson) => {
    //       return [...lessonCards, ...lesson.cards]
    //     }, [])]
    //   }, [])
    //   context.store.cardStatus = allCards.map((agg: CardStatus[], card: Card) => {
    //       const existingCard = existing.find((c: CardStatus) => c.courseId === course.id && c.cardId === course.cards[0].id)
    //       if (!existingCard) {
    //         // add new course
    //         agg.push(course);
    //       } else {
    //         // merge existing course with new course - ignoring deletes
    //         const mergedCourse = courseMerge(existingCard, course);
    //         agg.push(mergedCourse);
    //       }
    //       return agg;
    //   })
    // }
  },
})
