
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { useCoursesStore } from "@/stores/courses";
import InstallHelp from "@/components/InstallHelp.vue";

export default defineComponent({
  name: "CoursesPage",
  components: {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    InstallHelp
  },
  setup() {
    const router = useRouter();
    const coursesStore = useCoursesStore()
    return {
      courses: coursesStore.courses,
      router,
      courseCount: coursesStore.courseCount,
    };
  },
});
