import { defineStore } from 'pinia'

export const useSettingsStore = defineStore({
  id: 'settings',
  state: () => ({
    audioOn: false,
    onboardingLessonComplete: false,
    onboardingPlayerComplete: false,
    voiceName: 'Fiona',
    voicePitch: 1,
    voiceRate: 1,
  }),
  persist: {
    key: 'anc-settings',
  },
})