import { Card, Course, Lesson } from '@/models'

import { defineStore } from 'pinia'
import oneJump from '@/data/one-jump.json'
import puppyEssentials from '@/data/dana-pike-puppy-essentials.json'

const defaultCourses: Course[] = []
if (process.env.VUE_APP_COURSES === 'one-jump') {
  defaultCourses.push(oneJump)
} else if (process.env.VUE_APP_COURSES === 'puppy-essentials') {
  defaultCourses.push(puppyEssentials)
}

export const useCoursesStore = defineStore({
  id: 'courses',
  state: () => ({
    courses: defaultCourses,
  }),
  getters: {
    courseCount: (state) => state.courses.length,
    getCourseById:
      (state) =>
      (id: string | undefined): Course | undefined =>
        state.courses.find((course) => course.id === id),
    getLessonsByCourseId:
      (state) =>
      (courseId: string | undefined): Lesson[] => {
        const course = state.courses.find((course) => course.id === courseId)
        return course?.lessons || []
      },
    getCardsByCourseId:
      (state) =>
      (courseId: string | undefined): Card[] => {
        const course = state.courses.find((course) => course.id === courseId)
        return course?.lessons.map((lesson) => lesson.cards).flat() || []
      },
    getCards: (state) =>
      state.courses
        .map((course) => course.lessons.map((lesson) => lesson.cards).flat())
        .flat(),
  },
})
