export enum Proficiency {
  LEARNING = 'LEARNING',
  IMPROVING = 'IMPROVING',
  ALMOST = 'ALMOST',
  MASTERED = 'MASTERED',
}

export interface Card {
  id: number
  name: string
  isSelected?: boolean
  isReview?: boolean
  answer?: string
  behavior?: string
  suggestion?: string
  video?: string
}

export interface CardStatus {
  answer: string
  cardId: number
  courseId: string
  id: string
  isReview: boolean
  isSelected: boolean
  lastReview: number | null
  proficiency: Proficiency
}

export interface Lesson {
  id: number // tsc thinks this is a string, but it's actually a number
  name: string
  description: string
  cards: Card[]
}

export interface Course {
  id: string
  title: string
  copyright?: string
  description: string
  setup: string
  lessons: Lesson[]
}
